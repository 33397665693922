import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";

import * as _ from "lodash";

/**
 *
 *加密
 *
 *
 */
const encodeStr = (_str) => {
  let encodeStr = _str;
  for (let i = 0; i < 8; i++) {
    Utf8.parse(encodeStr);
    encodeStr = Base64.stringify(Utf8.parse(encodeStr));
  }

  return encodeStr;
};

/**
 *
 *解密
 *
 *
 */
const decodeStr = (_str) => {
  let decodeStr = _str;
  for (let i = 0; i < 8; i++) {
    const words = Base64.parse(decodeStr);
    decodeStr = words.toString(Utf8);
  }

  return decodeStr;
};

export { encodeStr, decodeStr };
