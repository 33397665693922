import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  useRef,
} from "react";
import AppContext from "../../Context/AppContext";
import * as API from "../../Services/APIService";
import { encodeStr } from "../../Utils/Encypt";
import formBg from "../../assets/images/form_bg.jpg";
import liffHelper from "../../Utils/liffHelper";
import { isAdminPermission } from "../../Services/PermissionService";

import {
  Button,
  Radio,
  Image,
  Space,
  Popconfirm,
  message,
  Input,
  Select,
  Avatar,
  Spin,
  Timeline,
  Divider,
  Table,
  Result,
  Progress,
  Tag,
} from "antd";
import {
  CameraOutlined,
  CloudUploadOutlined,
  UserOutlined,
  ClockCircleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";

import * as _ from "lodash";
import * as moment from "moment";
import "./SiteStatus.scss";

export default function SiteStatus(props) {
  const selectRef = useRef(null);

  const { updateTime, setUpdateTime, empProfile, setEmpProfile } =
    useContext(AppContext);

  const [salesList, setSalesList] = useState([]); //  業務人員清單
  const [siteListForSale, setSiteListForSale] = useState([]); // 案場清單 for 業務
  const [pickedEmpId, setPickedEmpId] = useState(undefined); // 所選到的業務  empId
  const [pickedSiteId, setPickedSiteId] = useState(undefined); // 所選到的案場 id
  const [isLoading, setIsLoading] = useState(false); // loading 指示
  const [isUploading, setIsUploading] = useState(false); // 是否正在上傳
  const [docsSubTaskList, setDocsSubTaskList] = useState([]); // 送審 - 子任務清單
  const [engiSubTaskList, setEngiSubTaskList] = useState([]); // 工程 - 子任務清單
  const [docsProgress, setDocsProgress] = useState(0); // 送審 - 排程進度
  const [engiProgress, setEngiProgress] = useState(0); // 工程 - 排程進度
  const [capacityHistoryList, setCapacityHistoryList] = useState([]); // 容量修改歷程
  const [capacity, setCapacity] = useState(0); // 容量

  const { Option, OptGroup } = Select;

  /* ◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤ componentDidMount ◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤ */
  /**
   *
   * componentDidMount
   *
   *
   */
  useEffect(() => {
    // 更新資料
    setUpdateTime(moment().format("YYYY-MM-DD HH:mm:ss.sss"));

    return () => {
      // componentWillUnmount is here!
    };
  }, []);

  /**
   *
   * componentDidMount
   *
   *
   */
  useEffect(() => {
    //emp profile為空,導向login

    if (_.size(empProfile) === 0) {
      // 轉跳  page
      props.history.push("/login");
      return;
    }

    async function fetchData() {
      // 取得業務部人員清單
      const salesDeptObj = {
        qDeptName: "業務部",
        ...empProfile,
      };
      //取得人員清單 by 部門
      const salesEmpListRes = await API.getSalesEmpList(salesDeptObj);

      if (_.get(salesEmpListRes, "RESULT") === "OK") {
        const salesEmpList = JSON.parse(_.get(salesEmpListRes, "DATA"));
        const placeHoldeOption = [
          {
            key: "78335432-5821-4a97-ba54-8d0a2ff6b85e",
            empId: "請選擇",
            empName: "請選擇",
          },
          {
            key: "4a86f405-9647-4d0b-880e-93f1ef043049",
            empId: "ALL",
            empName: "全部業務",
            linePictureUrl: "https://joeschmoe.io/api/v1/random",
          },
        ];
        setSalesList([...placeHoldeOption, ...salesEmpList]);
      }

      // 設定業務
      const { empId } = empProfile;
      setPickedEmpId(empId);
      // setPickedEmpId("C190604");

      // 取得案場清單  by 業務 line id
      /*

      const 
      const getSiteListBySiteMgrLineIdRes = await getSiteListBySiteMgrLineId(
        empProfile
      );
      if (_.get(getSiteListBySiteMgrLineIdRes, "RESULT") === "OK") {
        setSiteList(JSON.parse(_.get(getSiteListBySiteMgrLineIdRes, "DATA")));
      }*/
    }

    // 抓資料
    fetchData();

    return () => {
      // componentWillUnmount is here!
    };
  }, [updateTime, empProfile]);

  /**
   *
   * componentDidMount
   *
   *
   */
  useEffect(() => {
    async function fetchData() {
      // 取得案場清單  by 業務 emp id
      // empId 加密

      const encodeEmpId = encodeStr(pickedEmpId);
      const obj = {
        ...empProfile,
        qSaleEmpId: encodeEmpId,
      };

      const selectSiteListBySalesRes = await API.selectSiteListBySales(obj);

      if (_.get(selectSiteListBySalesRes, "RESULT") === "OK") {
        const ssss = JSON.parse(_.get(selectSiteListBySalesRes, "DATA"));

        const uniCityList = _.uniqBy(ssss, "city");

        const data = JSON.parse(_.get(selectSiteListBySalesRes, "DATA"));

        if (_.size(data) > 0) {
          const { siteId } = data[0];
          setPickedSiteId(siteId);
        }

        setSiteListForSale(data);
      }
    }

    // loading 開始
    setIsLoading(true);

    // 抓資料
    if (pickedEmpId !== undefined) {
      fetchData();
    }

    // loading 結束
    setIsLoading(false);

    return () => {
      // componentWillUnmount is here!
    };
  }, [pickedEmpId]);

  /**
   *
   * componentDidMount
   *
   *
   */
  useEffect(() => {
    async function fetchData() {
      // 取得子任務清單 by site id
      // siteId 加密

      const encodeSiteId = encodeStr(pickedSiteId);
      const obj = {
        ...empProfile,
        siteId: encodeSiteId,
      };

      // 送審 - 取得子任務清單 by site id
      const getDocsSubTaskListBySiteIdRes =
        await API.getDocsSubTaskListBySiteId(obj);

      if (_.get(getDocsSubTaskListBySiteIdRes, "RESULT") === "OK") {
        setDocsSubTaskList(
          JSON.parse(_.get(getDocsSubTaskListBySiteIdRes, "DATA"))
        );
      }

      // 工程 - 取得子任務清單 by site id
      const getEngiSubTaskListBySiteIdRes =
        await API.getEngiSubTaskListBySiteId(obj);

      if (_.get(getEngiSubTaskListBySiteIdRes, "RESULT") === "OK") {
        setEngiSubTaskList(
          JSON.parse(_.get(getEngiSubTaskListBySiteIdRes, "DATA"))
        );
      }

      //送審 - 取得送審進度
      const getDocsProgressRes = await API.getDocsProgress(obj);

      if (_.get(getDocsProgressRes, "RESULT") === "OK") {
        const data = JSON.parse(_.get(getDocsProgressRes, "DATA"));

        //progress
        if (_.get(data, "progress") === undefined) {
          setDocsProgress(0);
        } else {
          setDocsProgress(_.get(data, "progress"));
        }
      }

      //取得工程進度
      const getEngiProgressRes = await API.getEngiProgress(obj);

      if (_.get(getEngiProgressRes, "RESULT") === "OK") {
        const data = JSON.parse(_.get(getEngiProgressRes, "DATA"));

        //progress
        if (_.get(data, "progress") === undefined) {
          setEngiProgress(0);
        } else {
          setEngiProgress(_.get(data, "progress"));
        }
      }

      // 取得業務案場
      const getSaleSiteInfoRes = await API.getSaleSiteInfo(obj);
      if (_.get(getSaleSiteInfoRes, "RESULT") === "OK") {
        const saleSiteInfo = JSON.parse(_.get(getSaleSiteInfoRes, "DATA"));
        if (_.get(saleSiteInfo, "capacity") !== undefined) {
          setCapacity(_.get(saleSiteInfo, "capacity"));
        } else {
          setCapacity(0);
        }
      }

      // 取得 容量修改歷程
      const getCapacityHistoryListRes = await API.getCapacityHistoryList({
        ...obj,
        siteId: pickedSiteId,
      });

      if (_.get(getCapacityHistoryListRes, "RESULT") === "OK") {
        const myCapacityHistoryListRes = JSON.parse(
          _.get(getCapacityHistoryListRes, "DATA")
        );

        //保存容量修改歷程
        setCapacityHistoryList(myCapacityHistoryListRes);
      }
    }

    // loading 開始
    setIsLoading(true);

    // 抓資料
    if (pickedSiteId !== undefined) {
      fetchData();
    }

    // loading 結束
    setIsLoading(false);

    return () => {
      // componentWillUnmount is here!
    };
  }, [pickedSiteId]);

  /* ◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤ JSX ◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤ */

  return (
    <div className="site-status-comp">
      {/* 背景圖檔 */}
      {/* <div className="bg-image">
        {" "}
        <img src={formBg} alt="" />
      </div> */}
      {/* header */}
      <div className="header">
        <div className="title">CIRSOLAR</div>
        <div className="sub-title">案場進度</div>
      </div>
      <Spin tip="Loading..." spinning={isLoading}>
        {/* form area */}
        <div className="form-area">
          <div className="input-area">
            {/* 業務人員 */}

            <div className="input-item">
              <div className="label">業務人員 </div>
              <div className="input-field">
                <Select
                  style={{ width: "100%" }}
                  value={pickedEmpId}
                  defaultValue="請選擇"
                  onChange={(val) => setPickedEmpId(val)}
                  disabled={!isAdminPermission(empProfile, "SALE")}
                >
                  {salesList.map((item, index) => {
                    return (
                      <Option
                        value={item.empId}
                        key={`${item.key}-${index}`}
                        className="emp-select-option"
                        title={item.empId}
                      >
                        {/* 有 line 頭像 */}
                        {_.size(item.linePictureUrl) > 0 && (
                          <div>
                            <Avatar size="small" src={item.linePictureUrl} />
                            <span className="emp-select-option-name">
                              {item.empId} - {item.empName}
                            </span>
                          </div>
                        )}

                        {/* 沒有 line 頭像 */}
                        {_.size(item.linePictureUrl) === 0 && (
                          <div>
                            <Avatar
                              style={{ backgroundColor: "#87d068" }}
                              icon={<UserOutlined />}
                              size="small"
                            />
                            <span className="emp-select-option-name">
                              {item.empId} - {item.empName}
                            </span>
                          </div>
                        )}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>

            {/* 案場名稱 */}
            <div className="input-item">
              <div className="label" id="site-search-title">
                案場名稱 {_.size(siteListForSale)}{" "}
              </div>
              <div className="input-field">
                <Select
                  showSearch
                  value={pickedSiteId}
                  style={{ width: "100%" }}
                  placeholder="案場名稱"
                  optionFilterProp="children"
                  filterOption={(input, optionOrGroup) => {
                    const isGroup = Array.isArray(optionOrGroup.options);
                    if (isGroup) {
                      return false;
                    }
                    const { children } = optionOrGroup;

                    return _.includes(children, input);
                  }}
                  filterSort={(optionOrGroupA, optionOrGroupB) => {
                    return 1;
                  }}
                  onChange={(val) => {
                    setPickedSiteId(val);
                    selectRef.current.blur();
                  }}
                  onFocus={() =>
                    document
                      .getElementById("site-search-title")
                      .scrollIntoView(true)
                  }
                  onBlur={() => selectRef.current.blur()}
                  ref={selectRef}
                >
                  {_.uniqBy(siteListForSale, "city").map(
                    (cityItem, cityIndex) => (
                      <OptGroup
                        label={cityItem.city}
                        key={`dkckosk-${cityIndex}`}
                      >
                        {_.filter(
                          siteListForSale,
                          (o) => o.city === cityItem.city
                        ).map((item, index) => (
                          <Option
                            value={item.siteId}
                            key={`SI-${item.siteId}-${_.padStart(
                              index,
                              5,
                              "0"
                            )}`}
                          >
                            {item.siteName}
                          </Option>
                        ))}
                      </OptGroup>
                    )
                  )}

                  {/* {siteListForSale.map((item, index) => (
                  <Option value={item.siteId} key={`${item.siteId}-${index}`}>
                    {item.siteName}
                  </Option>
                ))} */}
                </Select>
              </div>
            </div>
          </div>
        </div>

        {/*  時間軸區 */}
        <div className="timeline-container">
          {/* 容量修改歷程  - 時間軸區 */}
          <div className="timeline-area">
            <div className="title capacity-title">
              容量修改歷程 -{" "}
              <span>
                {_.get(
                  _.find(
                    siteListForSale,
                    (item) => item.siteId === pickedSiteId
                  ),
                  "siteName"
                )}
              </span>
            </div>
            <Divider />

            <div className="last-capacity">
              <h4>最新容量: {capacity.toLocaleString()} kWp</h4>
            </div>
            {/* <Divider /> */}
            {_.size(capacityHistoryList) > 0 && <Divider />}

            {_.size(capacityHistoryList) === 0 && (
              <div className="hide-in-mobile">
                <Divider />
              </div>
            )}

            <Timeline>
              {_.size(capacityHistoryList) > 0 &&
                capacityHistoryList.map((item, index) => {
                  return (
                    <Timeline.Item key={`caphis-${item.capacity}-${index}`}>
                      <p>
                        {moment(item.dateUpdate).format("YYYY/MM/DD HH:mm:ss")}
                      </p>
                      <p>
                        容量: <Tag color="green">{item.capacity}</Tag>
                      </p>
                      <p>
                        備註:{" "}
                        <Tag color="blue">
                          {_.size(item.fixRemark) ? item.fixRemark : "---"}
                        </Tag>{" "}
                      </p>
                      <p>修改人: {item.userUpdateName}</p>
                    </Timeline.Item>
                  );
                })}
            </Timeline>
            {_.size(capacityHistoryList) === 0 && (
              <div className="no-data-area hide-in-mobile">
                <Result status="404" subTitle="no data" />
              </div>
            )}
          </div>

          {/* 送審進度- 時間軸區 */}
          <div className="timeline-area">
            <div className="title docs-title">
              送審進度 -{" "}
              <span>
                {_.get(
                  _.find(
                    siteListForSale,
                    (item) => item.siteId === pickedSiteId
                  ),
                  "siteName"
                )}
              </span>
              <Progress percent={docsProgress} status="active" />
            </div>
            <Divider />

            <Timeline>
              {_.size(docsSubTaskList) > 0 &&
                docsSubTaskList.map((item, index) => {
                  return (
                    <Timeline.Item
                      key={`${item.siteId}-${index}`}
                      color={item.progress === 100 ? "green" : "gray"}
                      dot={<CheckCircleFilled />}
                    >
                      {item.progress > 99 ? (
                        <p>
                          {moment(item.dateStart).format("YYYY/MM/DD")} -{" "}
                          {moment(item.dateEnd).format("YYYY/MM/DD")}(
                          {moment(item.dateEnd).diff(
                            moment(item.dateStart),
                            "day"
                          )}{" "}
                          天 )
                        </p>
                      ) : (
                        <p>{moment(item.dateStart).format("YYYY/MM/DD")}</p>
                      )}
                      <p>{item.taskName}</p>
                    </Timeline.Item>
                  );
                })}
            </Timeline>
            {_.size(docsSubTaskList) === 0 && (
              <div className="no-data-area">
                <Result status="404" subTitle="no data" />
              </div>
            )}
          </div>

          {/* 工程進度 - 時間軸區 */}
          <div className="timeline-area">
            <div className="title engi-title">
              工程進度 -{" "}
              <span>
                {_.get(
                  _.find(
                    siteListForSale,
                    (item) => item.siteId === pickedSiteId
                  ),
                  "siteName"
                )}
              </span>
              <Progress
                percent={engiProgress}
                status="active"
                strokeColor="#87d068"
              />
            </div>
            <Divider />
            <Timeline>
              {_.size(engiSubTaskList) > 0 &&
                engiSubTaskList.map((item, index) => {
                  return (
                    <Timeline.Item
                      key={`${item.siteId}-${index}`}
                      color={item.progress > 99 ? "green" : "gray"}
                      dot={<CheckCircleFilled />}
                    >
                      {item.progress > 99 ? (
                        <Fragment>
                          <p>
                            {moment(item.dateStart).format("YYYY/MM/DD")} -{" "}
                            {moment(item.dateEnd).format("YYYY/MM/DD")} (
                            {moment(item.dateEnd).diff(
                              moment(item.dateStart),
                              "day"
                            )}{" "}
                            天 )
                          </p>
                          <p> {item.engiName}</p>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <p>{moment(item.dateStart).format("YYYY/MM/DD")}</p>
                          <p>
                            {item.engiName}
                            <Progress
                              percent={item.progress}
                              steps={4}
                              className="item-progress"
                            />
                          </p>
                        </Fragment>
                      )}
                    </Timeline.Item>
                  );
                })}
            </Timeline>
            {_.size(engiSubTaskList) === 0 && (
              <div className="no-data-area">
                <Result status="404" subTitle="no data" />
              </div>
            )}
          </div>
        </div>
      </Spin>
    </div>
  );
}
