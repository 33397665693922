import liff from "@line/liff";

// ref:https://github.com/line/line-liff-v2-starter
// ref:https://github.com/BensonLiao/liff-react-boilerplate

const myLiffId = `${process.env.REACT_APP_LIFF_ID}`;

class liffHelper {
  /**
   *
   * 取得使用者 Line Profile
   *
   *
   */
  async getUserProfile() {
    console.log("getLineUserId");
    await this.initializeLiff();
    await this.doLogin();
    const profile = await this.getProfile();

    return profile;
  }

  /**
   *
   * 初始化 LIFF
   *
   *
   */
  initializeLiff() {
    console.log("initializeLiff");
    console.log("myLiffId");
    console.log(myLiffId);

    console.log("liff");
    console.log(liff);
    return new Promise((resolve, reject) => {
      liff
        .init({
          liffId: myLiffId,
        })
        .then(() => {
          // start to use LIFF's api
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  }

  /**
   *
   * Line 使用者登入
   *
   *
   */
  doLogin() {
    console.log("do login");
    return new Promise((resolve, reject) => {
      if (!liff.isLoggedIn()) {
        liff
          .login()
          .then((res) => {
            console.log(res);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve();
      }
    });
  }

  /**
   *
   * 取得 Profile
   *
   *
   */
  getProfile() {
    return new Promise((resolve, reject) => {
      if (!liff.isLoggedIn()) {
        // set `redirectUri` to redirect the user to a URL other than the front page of your LIFF app.
        liff.login();
      } else {
        liff
          .getProfile()
          .then((profile) => {
            console.log(profile);
            resolve(profile);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      }
    });
  }

  /**
   *
   * 幫使用者送訊息
   *
   *
   */

  async sendMessages(msg) {
    await this.initializeLiff();
    await this.doLogin();
    await this.doSendMessages(msg);
  }

  doSendMessages(msg) {
    console.log("==> doSendMessages");
    return new Promise((resolve, reject) => {
      liff
        .sendMessages(msg)
        .then((res) => {
          console.log("sendMessages res");
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  /**
   *
   * 關閉 LIFF App 視窗
   *
   *
   */
  closeLiffWindow() {
    return new Promise((resolve, reject) => {
      liff
        .closeWindow()
        .then((res) => {
          console.log("closeWindow res");
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
}

export default new liffHelper();
