import * as axios from "axios";

/**
 *
 * APIURL
 *
 *
 */
const getAPIURL = () => {
  return `${process.env.REACT_APP_API}`;
};

/**
 *
 * 取得員工資料 by Line Id
 *
 */
const getEmpInfoByLineId = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/get/emp/bylineid`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 * 取得人員清單 by 部門
 *
 */
const getEmpListByDeptName = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/get/emp/byDeptName`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 * 取得 業務部人員資料
 *
 */
export const getSalesEmpList = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/get/sales/list`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 * INIT DB
 *
 */
const initDB = async () => {
  const api = getAPIURL();
  const URL = `${api}/api/init/DB`;

  try {
    const response = await axios.get(URL);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 *  抓取子任務清單 by 監工 line id
 *
 */
const selectSubTaskForReport = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/get/work-order/list/for-report`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 *  上傳工程排程彙報結果
 *
 */
const addEngiReportResult = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/add/engi/report/result`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 *  取得案場清單  by 監工 line id
 *
 */
const getSiteListBySiteMgrLineId = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/get/site/list/for-report`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 *  取得案場清單 by 業務
 *
 */
export const selectSiteListBySales = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/sale/get/site/list/by/sales`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 *  工程 - 取得子任務清單 by site id
 *
 */
export const getEngiSubTaskListBySiteId = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/engi/get/sub/task/list`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 *  送審 - 取得子任務清單 by site id
 *
 */
export const getDocsSubTaskListBySiteId = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/docs/get/sub/task/list`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 *  送審 - 取得送審進度
 *
 */
export const getDocsProgress = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/docs/get/docs/progress`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 *  取得工程進度
 *
 */
export const getEngiProgress = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/get/engi/progress`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 * 更新員工資料
 *
 */
const updateEmpInfoByPKSelective = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/update/emp/info`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 * 取得容量下修歷程
 *
 */
export const getCapacityHistoryList = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/get/design/capacity/history`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 *  取得業務案場
 *
 */
export const getSaleSiteInfo = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/sale/get/site/info`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

export {
  getEmpInfoByLineId,
  getEmpListByDeptName,
  initDB,
  selectSubTaskForReport,
  addEngiReportResult,
  getSiteListBySiteMgrLineId,
  updateEmpInfoByPKSelective,
};
