import * as _ from "lodash";
//  Allen , DK, 裕文 佩姪
const bossList = ["C190601", "C200701", "C200603", "C190703"];

// admin 權限  - 業務
// 郭智昶 郭淳友 陳姿安 許雯琁 開一 彥甫
const adminListForSale = [
  "C190604",
  "C190606",
  "C190607",
  "C190902",
  "C210908",
  "C210810",
  ...bossList,
];

/**
 *
 * 是否有 admin 權限
 *
 *
 */
const isAdminPermission = (_empProfile, _module) => {
  const { empId, deptName } = _empProfile;
  switch (_module) {
    case "SALE": {
      // 財務可看
      if (_.startsWith(deptName, "財務")) {
        return true;
      }
      // admin 權限  - 業務
      return _.includes(adminListForSale, empId);
      break;
    }
    default: {
      return false;
      break;
    }
  }
};

export { isAdminPermission };
